import ar from '../blogs/ar.jpg'
import edge_computing from '../blogs/edge computing.jpg'
import metaverse from '../blogs/metaverse.jpg'
import obsolescence from '../blogs/obsolescence.jpg'
import yolov8 from '../blogs/yolov8.jpg'

const blogData = [
  {
    title: "Augmented Reality (AR)",
    description: "Explore the world of Augmented Reality and its transformative impact on industries.",
    imageURL:  ar ,
    instagramLink: "https://www.instagram.com/p/DBt1pWvSrQh/?img_index=1",
  },
  {
    title: "Edge Computing",
    description: "Discover how Edge Computing is shaping the future of decentralized data processing.",
    imageURL:  edge_computing ,
    instagramLink: "https://www.instagram.com/p/DAyH_7Hy8i1/?img_index=1",
  },
  {
    title: "Metaverse",
    description: "Dive into the Metaverse: a convergence of virtual and physical realities.",
    imageURL: metaverse ,
    instagramLink: "https://www.instagram.com/p/DC9A91eSnYF/?img_index=1",
  },
  {
    title: "Planned Obsolescence",
    description: "Understand the concept of Planned Obsolescence and its effects on technology and society.",
    imageURL:  obsolescence ,
    instagramLink: "https://www.instagram.com/p/DEH91fcymdy/?img_index=1",
  },
  {
    title: "YOLOv8",
    description: "Learn about YOLOv8: the latest in real-time object detection and computer vision.",
    imageURL:  yolov8 ,
    instagramLink: "https://www.instagram.com/p/C-YE0lSoOCd/?img_index=1",
  },
];

export default blogData;
  